<template>
  <!-- 发送失败弹框 -->
  <div class="fail_box">
    <!-- 详情 -->
    <el-dialog
      :title="$t('label.detailinf')"
      :before-close="difference"
      :visible.sync="failInSendD"
      :width="width"
      :close-on-click-modal="false"
    >
      <!-- 发送失败内容联系人盒子 -->
      <div class="failure_box">
        <div class="check_box">
          <div class="content_box">
            <div
              class="check_data_box"
              v-for="(item, index) in checkListData"
              :key="index"
            >
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  :label="item.id"
                  type="checkbox"
                  @change="contactCheckBox"
                >
                  <div class="check_content_box">
                    <div class="check_name">{{ item.name }}</div>
                    <div class="number">{{ item.number }}</div>
                    <div class="text">{{ item.text }}</div>
                  </div>
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="fail_button_box">
          <div class="fail_button">
            <!-- 取消 -->
            <el-button size="mini" @click="changeCancel">
              {{ $t("button_partner_cancel") }}</el-button
            >
            <!-- 重新发送 -->
            <el-button type="primary" size="mini" @click="changeResend">{{
              $t("label.weixin.againsend")
            }}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    failInSend: {
      type: Boolean,
    },
    width: {
      type: String,
      default: "400px",
    },
  },
  data() {
    return {
    failInSendD:this.failInSend,
      checkList: [],
      // 选项框数据
      checkListData: [
        {
          id: 1,
          name: "张三张三张三张三",
          number: 10086111234,
          text: "发送失败",
        },
        {
          id: 2,
          name: "李四",
          number: 10086111234,
          text: "发送失败",
        },
        {
          id: 3,
          name: "王五",
          number: 10086,
          text: "发送失败",
        },
        {
          id: 4,
          name: "赵六",
          number: 10086,
          text: "发送失败",
        },
        {
          id: 5,
          name: "赵六",
          number: 10086,
          text: "发送失败",
        },
        {
          id: 6,
          name: "赵六",
          number: 10086,
          text: "发送失败",
        },
      ],
    };
  },
  methods: {
    //   点击差号隐藏弹框
    difference() {
      this.$emit("difference");
    },
    // 点击取消隐藏弹框
    changeCancel() {
      this.$emit("changeCancel");
    },
    // 点击重新发送事件
    changeResend() {
      if (this.checkList.length > 0) {
        this.$emit("changeResend");
      } else {
        this.$message.warning(this.$i18n.t("message.please.choose.contact")); //`请您选择要添加的联系人`
      }
    },
    // 勾选发送失败的联系人
    contactCheckBox() {},
  },
};
</script>

<style lang="scss" scoped>
.failure_box {
  width: 400px;
  height: 239px;
  .check_box {
    width: 100%;
    height: 177px;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #d8d8d8;
    .content_box {
      width: 100%;
      height: 100%;
      padding: 10px 0px 10px 36px;
      overflow: auto;
    }
    .check_data_box {
      padding-top: 10px;
    }
    .check_content_box {
      display: flex;
    }
    .check_name {
      width: 68px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .number {
      width: 100px;
      padding-left: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .text {
      padding-left: 84px;
      color: #cc0000;
    }
  }
}
.fail_button_box {
  width: 100%;
  height: 60px;
  .fail_button {
    padding: 16px 0 0 227px;
  }
}
::v-deep .el-dialog__body {
  padding: 0px;
}
</style>