/* eslint-disable no-undef */
<template>
  <div class="sms_box">
    <!-- {{getRouterParam}} -->
    <!-- 未开启短信网关显示这个页面 -->
    <!-- <div class="not_open_page" v-if="defaultSmsTabPage"> -->
    <!-- 提醒文字盒子 -->
    <!-- <div class="reminder_text_box">
        <div class="open_gateway">开通短信网关</div>
        <div class="reminder_box">
          离开系统给潜在客户或联系人发送短信已成为过去。
          在cloudcc上开通短信网关，可直接在平台上收发短信，
          并可关联到潜在客户、联系人，沟通历史时间线一目了然。
        </div>
      </div> -->
    <!-- 中间内容图片 -->
    <!-- <div class="sms_img_box">
        <div class="sms_img">
          <img src="@/assets/noteSms/Unchecked.svg" />
        </div>
      </div> -->
    <!-- 开启网关按钮 -->
    <!-- <div class="button_box">
        <div class="button_style">
          <el-button type="primary">开通短信网关</el-button>
          <el-button @click="associationGateway">已关联，去发短信</el-button>
        </div>
      </div>
    </div> -->
    <!-- 开通短信网关显示这个页面 -->
    <div class="sms-page" v-if="openSmsTabPage">
      <!-- 左侧菜单栏 -->
      <div class="left-menu-bar">
        <!-- 顶部选择和搜索框 -->
        <div class="selection-box">
          <!-- 选择框 -->
          <div class="left-selection">
            <el-select v-model="value">
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.name"
                :value="item.id"
                @click.native="getUserInformationList(item)"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 搜索框 -->
          <div class="search-box">
            <!-- 请按照手机号或者人名搜索 -->
            <el-input
              v-model="input"
              :placeholder="$t('vue_label_SMS_name_or_phone_number_lookup')"
              suffix-icon="el-icon-search"
              @input="contactListSearch"
            ></el-input>
          </div>
          <!-- 右侧点击短信图片 -->
          <div class="short-message" @click="clickSmsDialog">
            <svg
              style="display: inline-block; width: 14px; height: 13px"
              aria-hidden="true"
            >
              <use href="#icon-sms"></use>
            </svg>
          </div>
        </div>
        <!-- 短信弹框 -->
        <short-message-dialog
          ref="mychild"
          :dialogTableVisible="dialogTableVisible"
          @confirmationMessage="confirmationMessage"
          @sendSeparately="sendSeparately"
          @cancelMssage="cancelMssage"
        />
        <!-- 内容标题 -->
        <div class="context-title">
          <i class="el-icon-arrow-down"></i>
          <!-- 短信箱 -->
          <span>{{ smsName }} {{ $t("vue_label_SMS_messagebox") }}</span>
        </div>
        <!-- 内容数据 -->
        <!-- 没数据时显示这个div -->
        <div class="context-data" v-if="smsContent.length === 0">
          <!-- 没有可显示项目 -->
          <div class="no_project">{{ $t("label.file.norecord") }}</div>
        </div>
        <!-- 有数据时显示这个div -->
        <div
          class="context-data"
          v-if="smsContent.length > 0"
          v-loading="loadingStatus"
        >
          <div
            v-for="(item, index) in smsContent"
            :key="index"
            @click="clickShortMessage(item, index)"
            :class="{ dataStyle: index == mainIndex }"
          >
            <!-- 数据 -->

            <div class="data-box" v-if="item.objid">
              <!-- 头像，名称，时间盒子 -->
              <div class="top-box">
                <!-- 左边图片 -->
                <!-- isread=0:就是未读，1是已读 -->
                <div class="fillet_box" v-if="item.isread == '0'"></div>
                <div class="left_img_box">
                  <svg
                    class="iconSvg"
                    aria-hidden="true"
                    v-if="item.objid === 'contact' && item.length < 2"
                  >
                    <use href="#icon-potential"></use>
                  </svg>
                  <svg
                    class="iconSvg"
                    aria-hidden="true"
                    v-if="
                      (item.objid == 'lead' && item.length < 2) ||
                      item.name == null
                    "
                  >
                    <use href="#icon-contacts"></use>
                  </svg>
                  <svg
                    class="iconSvg"
                    aria-hidden="true"
                    v-if="item.length > 1 && item.incoming === '0'"
                  >
                    <use href="#icon-Masshair"></use>
                  </svg>
                  <svg
                    class="iconSvg"
                    aria-hidden="true"
                    v-if="item.length > 1 && item.incoming === '1'"
                  >
                    <use href="#icon-incomingsms1"></use>
                  </svg>
                </div>

                <!-- 右边内容 -->
                <div class="right_content_box">
                  <!-- 姓名，人数，时间 -->
                  <div class="lower-box">
                    <!-- 客户姓名 -->
                    <div class="span-name-box">
                      <p
                        class="span-name"
                        @click="customerDetailsPage(item, item.length)"
                      >
                        {{ item.name }}
                      </p>
                    </div>
                    <div class="number_and_time">
                      <!-- 等多少人 -->
                      <div class="display_name" v-if="item.length >= 3">
                        <p>
                          {{ $t("vue_label_SMS_otherpeople", [item.length]) }}
                        </p>
                      </div>
                      <!-- 时间 -->
                      <div class="span-time">
                        <p class="time">{{ item.emaildate }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- 内容 -->
                  <div class="exhibition_content" v-html="item.htmlbody"></div>
                </div>
              </div>
            </div>
            <!-- 分割线 -->
            <!-- <div class="dividing-line"></div> -->
          </div>
        </div>
      </div>
      <!-- 右侧内容 -->
      <!-- 进来后默认展示样式 -->
      <div class="right-context" v-show="isDefaultSelect">
        <div class="unchecked_img">
          <svg
            style="display: inline-block; width: 315px; height: 190px"
            aria-hidden="true"
          >
            <use href="#icon-nonews"></use>
          </svg>
          <!-- 未选择记录 -->
          <div class="record">{{ $t("vue_label_SMS_noselected") }}</div>
          <div class="list_record">
            <!-- 从列表中打开记录，以开始启用。 -->
            {{ $t("vue_label_SMS_open_record_from_start_activation") }}
          </div>
        </div>
      </div>
      <!-- 选中后展示样式 -->
      <div class="right-context" v-show="isSelect" v-loading="smsBoxLoading">
        <!-- 短信标题名称 -->
        <div class="send-out-title" v-if="recordContent.length > 0">
          <!-- <div class="customer-name"> -->
          <span v-if="userName.length < 1" class="contact_name">
            {{ msgBoxTitle }}
          </span>
          <template v-for="(itemy, index) in userName">
            <span class="contact_name" v-if="localTitle" :key="index">
              <!-- <span @click="q(itemy)"> {{ itemy + " " }} </span> -->
              <span @click="smsContactJump(itemy)" v-if="itemy.name">
                <!-- {{ itemy.name + " ," }} -->
                <!--              {{ itemy.name !== undefined ? itemy.name + " ," : null }}-->
                {{ msgBoxTitle }}
              </span>
              <span v-else>
                {{ msgBoxTitle }}
              </span>
            </span>
          </template>
          <template v-for="(itemy, index) in userName">
            <span
              class="contact_name"
              v-if="detailsName"
              @click="smsContactJump(itemy)"
              :key="index"
            >
              {{ itemy.name }}
            </span>
          </template>

          <!-- <span class="contact_name" v-if="detailsTitle">{{ userName }}</span> -->
          <!-- </div> -->
          <!-- <div class="send-out-name">短信由张三发送</div> -->
        </div>
        <div class="send-out-title" v-if="recordContent.length === 0"></div>
        <!-- <div class="dividing_line"></div> -->

        <div class="chat-content" id="window">
          <!-- recordContent 聊天记录数组-->
          <div v-for="(itemc, indexc) in reverseData" :key="indexc">
            <!-- 对方 -->
            <div class="word" v-if="itemc.incoming == '1'">
              <!-- <img :src="itemc.headUrl" /> -->
              <div class="info">
                <!-- <p class="time">
                    {{ itemc.nickName }}
                  </p> -->
                <div class="info-content" v-html="itemc.htmlbody"></div>
                <div class="message_time">
                  {{ dataArrName }}
                  {{ itemc.emaildate | yearMonthDayTime(countryCode) }}
                </div>
              </div>
            </div>
            <!-- 我的 -->
            <div class="word-my" v-else>
              <div class="info">
                <div class="info-content" v-html="itemc.htmlbody"></div>
                <div class="Sender_time">
                  {{ itemc.emaildate | yearMonthDayTime(countryCode) }}
                </div>
                <div class="failure_details" v-if="failureDetailsBoxs">
                  <!-- 失败 -->
                  <span class="fail">{{ $t("label.systemlog.f") }}:</span>
                  <!-- 详情 -->
                  <span class="details" @click="changeDetails">{{
                    $t("label.detailinf")
                  }}</span>
                </div>
                <div class="failInSend" v-if="itemc.sendstatus !== '0'">
                  <svg
                    style="
                      display: inline-block;
                      width: 17px;
                      height: 17px;
                      padding-top: 2px;
                    "
                    aria-hidden="true"
                  >
                    <use href="#icon-warning"></use>
                  </svg>
                  <!-- 发送失败 -->
                  <span>{{ $t("label.weixin.senderror") }}</span>
                </div>
              </div>
              <!-- <img :src="itemc.headUrl" /> -->
            </div>
          </div>
        </div>

        <!-- 短信消息区 -->
        <!-- <div class="message-area"></div> -->
        <!-- 模板，表情 -->
        <div class="quick-box">
          <!-- <el-popover
            placement="top"
            width="283"
            trigger="click"
            @hide="hidePopver"
            popper-class="template_search_box"
          > -->
          <!-- 搜索 -->
          <!-- <el-input
              :placeholder="$t('label.quickbooks.searchs')"
              prefix-icon="el-icon-search"
              v-model="searchVal"
              @input="searchTemplateChangeEvent"
            >
            </el-input> -->

          <!-- <div class="template_box"> -->
          <!-- 全部模板 -->
          <!-- <div class="whole">{{ $t("vue_label_SMS_alltemplates") }}</div> -->
          <!-- <div class="template-style">
                <div
                  v-for="(items, indexs) in templateData"
                  :key="indexs"
                  class="template_data"
                  v-show="notYetData"
                  @click="selectTemplate(items)"
                >
                  {{ items.name }}
                </div> -->
          <!-- 暂无数据 -->
          <!-- <div class="not_yet" v-show="!notYetData">
                  {{ $t("vue_label_SMS_nodata") }}
                </div>
              </div>
            </div> -->
          <div
            class="template-img"
            slot="reference"
            @click="showDialog('message')"
          >
            <svg
              style="display: inline-block; width: 18px; height: 18px"
              aria-hidden="true"
            >
              <use href="#icon-Template"></use>
            </svg>
            <emailTemplateFront
              ref="emailTemplateFront"
              @deliverTemplate="selectTemplate"
              :showRelateId="showRelateid"
            ></emailTemplateFront>
          </div>
          <!-- </el-popover> -->
          <div class="expression-img" v-show="isText == 'true'">
            <VueEmojiBox
              targetId="demoText"
              :baseUrl="baseUrl"
              :customEmojis="emojis"
              :customCategories="emojiCategory"
              height="200px"
              @select="selectEmoji"
              @change="bindChange"
              label=" "
              :visible="false"
            />
          </div>
        </div>
        <!-- 输入消息模块 -->
        <div class="mian_box">
          <el-input
            v-if="isText == 'true'"
            ref="mark"
            type="textarea"
            id="demoText"
            :rows="6"
            resize="none"
            v-model="textarea"
            @blur="handleInputBlur"
            @input="handleInputChange"
            @keydown.enter.native="carriageReturn($event)"
            @keyup.delete.native="handleKeyBack($event)"
          ></el-input>
          <wangeditor
            ref="wangeditor"
            @editorContent="editorContent"
            v-else
            :editorheight="110"
          >
          </wangeditor>
        </div>

        <div
          class="button-box"
          style="
            display: flex;
            justify-content: flex-end;
            margin: 0px 37px 0 0px;
          "
        >
          <!-- <el-tooltip placement="bottom-end" content="发送消息不能为空"  v-model="disabled"> -->
          <!-- 发送 -->
          <el-button
            size="mini"
            type="info"
            disabled
            v-show="msgBoxValue.length === 0"
            >{{ $t("label_chatter_send") }}</el-button
          >
          <!-- 发送 -->
          <!--          <el-button-->
          <!--            :loading="loading"-->
          <!--            size="mini"-->
          <!--            type="primary"-->
          <!--            @click="changeSendOut()"-->
          <!--            v-show="msgBoxValue.length > 0"-->
          <!--            >{{ $t("label_chatter_send") }}</el-button-->
          <!--          >-->
          <el-dropdown
            split-button
            type="primary"
            size="mini"
            :loading="loading"
            v-show="msgBoxValue.length > 0"
            @click="changeSendOut()"
          >
            <!-- 发送 -->
            {{ $t("label_chatter_send") }}
            <el-dropdown-menu slot="dropdown">
              <!--分别发送-->
              <el-dropdown-item @click.native="sendSingle">{{
                $t("label.emailobject.emailsend.sendindividually")
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- </el-tooltip> -->
        </div>

        <!-- 发送失败弹框 -->
        <sendFailedPopup
          :failInSend="failInSend"
          @difference="difference"
          @changeCancel="changeCancel"
          @changeResend="changeResend"
        >
        </sendFailedPopup>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 短信组件
 */
import {
  getListContacts,
  getSubordinateUserList,
  getTextMessageGetTextMessageTemplata,
} from "./api.js"; //查询短信列表
import shortMessageDialog from "./components/shortMessageDialog"; //短信弹框组件
import VueEmojiBox from "vue-emoji-box"; //表情包
import { emojiCategory, emojis } from "@/assets/emoji"; //表情包
import sendFailedPopup from "./components/sendFailedPopup"; //f发送失败弹框
import timeFormatting from "@/config/SOtime.js"; //格式化时间戳
import { getTextMessageSendTextMessage, getChangeRead } from "./api.js"; //短信接口
import * as CommonObjApi from "./api.js"; //获取点击跳转权限接口
import emailTemplateFront from "@/views/Email/components/email-template-front";
import wangeditor from "@/components/emailEditor";

export default {
  components: {
    shortMessageDialog,
    VueEmojiBox,
    sendFailedPopup,
    emailTemplateFront,
    wangeditor,
  },
  data() {
    return {
      // 标题显示隐藏
      localTitle: true,
      detailsTitle: false,
      // 短信箱显示的名称
      smsName: this.$i18n.t("label_tabpage_myz"),
      // 需要查询的客户信息的id
      customerInformationId: "",
      // 短信已读未读状态id
      smsStatusId: "",
      // 左侧联系人loadingStatus
      loadingStatus: false,
      // disabled:true,
      // 滚动加载
      flag: true,
      loading: false,
      // 发送时需要的用户id
      userId: "",
      relateidObject: "",
      // 消息框用户名
      userName: [],
      // 当前的时间
      currentTime: null,
      // 短信箱时间显示
      messageDate: "",
      //时区
      timeZone: "",
      // 模板搜索关键字
      templateKeywords: "",
      // 发送按钮禁用非禁用
      isdisabledFn: false,
      isdisabledBtn: true,
      // 暂无数据
      notYetData: true,
      // 主页搜索框的值(模板)
      searchVal: "",
      // 右侧短信客户name
      customerName: [],
      // 整个短信页面展示和隐藏
      defaultSmsTabPage: false,
      openSmsTabPage: true,

      // 右侧div展示和隐藏
      isSelect: false,
      isDefaultSelect: true,
      // 鼠标点击变色
      mainIndex: null,
      // 表情包
      emojiCategory,
      emojis,
      baseUrl: "../assets/emoji.js",
      // 短信弹框的显示隐藏
      dialogTableVisible: false,
      // 发送失败弹框的显示隐藏
      failInSend: false,
      // 短信模板
      SmsTemplate: false,
      // 选择框
      options: [
        // {
        //   value: this.$i18n.t('chatter.poll.option1'),
        //   label: this.$i18n.t('label_tabpage_myz'),
        // },
        // {
        //   value: "选项2",
        //   label: "全部",
        // },
      ],
      value: this.$i18n.t("label_tabpage_myz"), //我的
      // 搜索框
      input: "",
      // 编辑消息文本
      textarea: "",

      // 联系人列表内容数据
      smsContent: [
        // {
        //   name: "张三三,张三三,张三三,张三三,张三三,张三三,张三三",
        // },
        // {
        //   name: "张三三",
        // },
        // {
        //   name: "张三三",
        // },
        // {
        //   name: "张三三",
        // },
      ],
      // 模板数据
      templateData: [],
      // 短信消息数据
      recordContent: [
        // {
        //   incoming
        //   time: "下午12:30 9/30/2020",
        //   Sender: "Ruru发送给黎明-下午12:30 9/",
        //   contactText: "而解了。s",
        // },
        // {
        //   id: 2,
        //   time: "下午12:30 9/30/2020",
        //   Sender: "Ruru发送给黎明-下午12:30 9/",
        //   contactText: "小箭头一切都迎刃而解了。s",
        // },
        // {
        //   id: 1,
        //   time: "下午12:30 9/30/2020",
        //   Sender: "Ruru发送给黎明-下午12:30 9/",
        //   contactText: "hahahhaahahahahahahahahahaha",
        // },
        // {
        //   id: 1,
        //   time: "下午12:30 9/30/2020",
        //   Sender: "Ruru发送给黎明-下午12:30 9/",
        //   contactText:
        //     "sqsssss示ppppppppppp了三角形小箭头就容易了。一种方法就是制作这样一个三角形箭头的图片，然后定位在矩形框上。但这种解决办法在后期更改气泡框会很不方便，可能每修改一次气泡框都要重新制作一个三角形小图标。如果我们能够直接用HTML和CSS代码实现这样一个三角形小箭头一切都迎刃而解了。s",
        // },
        // {
        //   id: 2,
        //   time: "下午12:30 9/30/2020",
        //   Sender: "Ruru发送给黎明-下午12:30 9/",
        //   contactText:
        //     "啦啦啦啦外乎就是一个矩形框+一个指示方向的三角形小箭头，要制作出这样的气泡框，如果解决了三角形小箭头就容易了。一种方法就是制作这样一个三角形箭头的图片，然后定位在矩形框上。但这种解决办法在后期更改气泡框会很不方便，可能每修改一次气泡框都要重新制作一个三角形小图标。如果我们能够直接用HTML和CSS代码实现这样一个三角形小箭头一切都迎刃而解了。s",
        // },
      ],
      // 相关项id
      relateid: "",
      // 每一个名字加上点击事件的数组
      dataArrName: "",
      // 别的页面点击进来传的id请求短信框里面的聊天数据
      relateidRequest: "",
      getId: "",
      // 右侧标题详情name
      detailsName: false,
      // 群发发送失败消息弹框显示隐藏
      failureDetailsBox: false,
      failureDetailsBoxs: false,
      // 失去焦点光标最后一次出现的位置
      blurIndex: "",
      // 搜索传参
      searchStatus: false,
      // 右侧短信箱Loading
      smsBoxLoading: false,
      countryCode: this.$cookies.get("countryCode"),
      msgBoxTitle: "", // 消息框标题
      msgBoxValue: "", // 消息输入框的值
      isText: "true",
      editorText: "",
      showRelateid: "",
    };
  },
  watch: {
    search(input) {
      if (input === "") {
        //如果为空，执行方法获取list
        this.listContactRequest();
      }
    },
    templateSearch(searchVal) {
      if (searchVal === "") {
        //如果为空，执行方法获取模板数据
        this.searchTemplateChangeEvent();
      }
    },
    $route: {
      immediate: true,
      handler(to) {
        if (to.query.id) {
          //监听到路由（参数）改变
          // 拿到目标参数 to.query.relatedid 去再次请求数据接口
          // 筛选数据请求
          let relateidData = to.query.id;
          if (relateidData) {
            this.filterListData(relateidData);
          }
        }
      },
    },
  },
  computed: {
    // getRouterParam(){
    //   if(this.$route.query.id ){
    //     this.getId = this.$route.query.id;
    //   }
    //    return this.$route.query.id || ""; //想使用计算属性就return 要使用id就弄个变量赋值进去
    // },
    reverseData() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.recordContent.reverse();
    },
    // 主页联系人列表搜索
    search() {
      return this.input;
    },
    // 模板搜索
    templateSearch() {
      return this.searchVal;
    },
  },
  mounted() {
    // 短信
    document.title = this.$setTitle(this.$i18n.t("label.setupsms"));

    // 获取到相关项id
    this.$Bus.$on("deliver-relateid", this.ebFn);
  },
  beforeDestroy(){
    this.$Bus.$off("deliver-relateid", this.ebFn);
  },
  created() {
    //获取短信箱用户下拉框下级列表
    this.getSubordinateUserList();
    this.listContactRequest(); //联系人列表请求
    //
    this.currentTime = Date.parse(new Date()).toString().substring(0, 10);
    this.SmsTemplateRequest();
    // 滚动加载判断
    window.onscroll = () => {
      // 当下一次请求没有回到客户端之前，不发送下一次请求
      if (!this.flag) return;
      // 为了减少请求的次数；
      this.rollingLoad();
    };
  },
  methods: {
    ebFn(res){
      this.relateid = res;
    },
    editorContent(val) {
      this.editorText = val;
    },
    showDialog(message) {
      this.$refs.emailTemplateFront.showDialog(message);
    },
    // 监听光标失去焦点后最后点击的位置
    // eslint-disable-next-line no-unused-vars
    handleInputBlur(e) {
      // this.$refs['mark'].focus()
    },
    // bindChange(item) {
    //     this.textarea += item.text;
    //     
    //     document.getElementById('demoText').focus();
    //     renewHtml;
    //   },
    // 文本框输入时，失去焦点和光标时，记录光标的位置，并且往最后一次光标的位置添加表情包
    // eslint-disable-next-line no-unused-vars
    selectEmoji(emoji) {
      // var elInput = document.getElementById("demoText"); //根据id选择器选中对象
      // var startPos = elInput.selectionStart; // input 第0个字符到选中的字符
      // var endPos = elInput.selectionEnd; // 选中的字符到最后的字符
      // if (startPos === undefined || endPos === undefined) return;
      // var txt = elInput.value;
      // // 将表情添加到选中的光标位置
      // var result =
      //   txt.substring(0, startPos) + emoji.emoji + txt.substring(endPos);
      // elInput.value = result; // 赋值给input的value
      // // 重新定义光标位置
      // elInput.focus();
      // elInput.selectionStart = startPos + emoji.emoji.length;
      // elInput.selectionEnd = startPos + emoji.emoji.length;
      // this.textarea = result; // 赋值给表单中的的字段
    },

    // 监听模板弹框隐藏时的方法
    hidePopver() {
      // 处理
      this.searchVal = "";
      this.SmsTemplateRequest();
    },
    // 点击客户跳转到详情页
    smsContactJump(val) {
      CommonObjApi.getPermissionById({ id: val.id }).then((res) => {
        if (res.data.isQuery) {
          let routeData = this.$router.resolve({
            path: `/commonObjects/detail/${val.id}/DETAIL`,
          });
          window.open(routeData.href, "_blank");
        } else {
          return;
        }
      });
      // this.$router.push({
      //   path: `/commonObjects/detail/${val.id}/DETAIL`,
      // });
    },
    // 点击用户跳转详情页
    customerDetailsPage(val, dataArray) {
      if (dataArray > 1) {
        return;
      } else {
        CommonObjApi.getPermissionById({ id: val.relatedid }).then((res) => {
          if (res.data.isQuery) {
            // this.$router.push({
            //   path: `/commonObjects/detail/${val.relatedid}/DETAIL`,
            // });
            let routeData = this.$router.resolve({
              path: `/commonObjects/detail/${val.relatedid}/DETAIL`,
            });
            window.open(routeData.href, "_blank");
          } else {
            return;
          }
        });
        // this.$router.push({
        //   path: `/commonObjects/detail/${val.relateid}/DETAIL`,
        // });
      }
    },
    // 获取短信箱用户下拉框下级列表
    async getSubordinateUserList() {
      let res = await getSubordinateUserList();
      this.options = res.data;
      let item = {
        // 我的
        name: this.$i18n.t("label_tabpage_myz"),
      };
      this.options.unshift(item);
    },
    async getUserInformationList(val) {
      if (val.name === this.$i18n.t("label_tabpage_myz")) {
        //我的
        this.smsName = this.$i18n.t("label_tabpage_myz");
        this.customerInformationId = "";
        this.loadingStatus = true;
        this.listContactRequest();
        this.isDefaultSelect = true;
        this.isSelect = false; //右侧样式展示
      } else if (val.name !== this.$i18n.t("label_tabpage_myz")) {
        //我的
        this.smsName = val.name;
        this.customerInformationId = val.id;
        this.loadingStatus = true;
        this.isSelect = false; //右侧样式展示
        this.listContactRequest();
        this.isDefaultSelect = true;
        this.isSelect = false; //右侧样式展示
      }
    },
    // 左侧联系人列表搜索
    async contactListSearch(val) {
      this.listContactRequest(val);
    },
    // 获取左侧联系人列表数据
    async listContactRequest(val) {
      this.loadingStatus = true;
      let params = {
        id: "",
        searchWord: val,
        userId: this.customerInformationId,
        page: "",
        pageSize: "",
      };
      let res = await getListContacts(params);
      this.loadingStatus = false;
      this.smsContent = res.data;
      // 滚动加载方法
      if (this.smsContent.length !== 0) {
        this.flag = true; // 请求成功后，把flag变回true；
      } else {
        return;
      }

      // 联系人列表时间处理方式
      this.smsContent.forEach((item) => {
        let a = {
          time1: this.$moment(timeFormatting.time3(this.currentTime)).format(
            "YYYY/MM/DD"
          ),
          time2: parseInt(
            (new Date(timeFormatting.time3(this.currentTime)) -
              new Date(timeFormatting.time3(item.emaildate))) /
              (1000 * 60 * 60 * 24)
          ),
        };
        let b = {
          sendTime1: this.$moment(item.emaildate).format("YYYY/MM/DD"),
        };

        if (a.time1 == b.sendTime1) {
          item.emaildate = this.$moment(item.emaildate).format("hh:mm A");
        } else if (a.time2 < 7) {
          item.emaildate = this.$moment(item.emaildate).format("dddd hh:mm A");
        } else if (a.time2 >= 7) {
          item.emaildate = this.$moment(item.emaildate).format(
            "YYYY/MM/DD hh:mm A"
          );
        }
        // 计算返回客户姓名的人数显示等多少人
        if (item.name == null) {
          return;
        } else {
          item.length = item.name.split(",").length;
        }
      });
    },
    // 筛选点击跳过来的数据,并且请求列表数据
    async filterListData(val) {
      // val是记录id
      let params = {
        id: "",
        searchWord: "",
        userId: this.customerInformationId,
        page: "",
        pageSize: "",
      };
      let res = await getListContacts(params);
      this.smsContent = res.data;
      // 筛选联系人列表内容数据
      this.smsContent = this.smsContent.filter((item) => {
        let b = val;
        return item.relateid === b;
      });

      // 过滤出传过来的id跟原有数据中相同的那个记录id,并且拿到这个记录id去请求列表数据和短信框里面的数据
      this.smsContent.map((item) => {
        let a = {
          time1: this.$moment(timeFormatting.time3(this.currentTime)).format(
            "YYYY/MM/DD"
          ),
          time2: parseInt(
            (new Date(timeFormatting.time3(this.currentTime)) -
              new Date(timeFormatting.time3(item.emaildate))) /
              (1000 * 60 * 60 * 24)
          ),
        };
        let b = {
          sendTime1: this.$moment(item.emaildate).format("YYYY/MM/DD"),
        };

        if (a.time1 == b.sendTime1) {
          item.emaildate = this.$moment(item.emaildate).format("A hh:mm");
        } else if (a.time2 < 7) {
          item.emaildate = this.$moment(item.emaildate).format("A hh:mm dddd");
        } else if (a.time2 >= 7) {
          item.emaildate = this.$moment(item.emaildate).format(
            "A hh:mm YYYY/MM/DD"
          );
        }
        if (item.name !== "") {
          item.length = item.name.split(",").length;
        } else {
          return;
        }
        this.relateidRequest = item.relateid;
        this.detailsPagelist(this.relateidRequest, this.smsContent);
      });
    },
    // 别的页面点击进来请求列表和短信箱数据请求
    async detailsPagelist(val, data) {
      this.smsContent = data;
      let params = {
        id: val,
        searchWord: "",
        userId: this.customerInformationId,
        page: "",
        pageSize: "",
      };
      let res = await getListContacts(params);

      // val是记录id
      this.isSelect = true; //右侧样式展示
      this.isDefaultSelect = false; //默认样式展示
      this.recordContent = res.data;

      this.gobottom();
      this.userName = [];
      data.map((item) => {
        this.userName.push({
          id: item.relateid,
          name: item.name,
        });
      });

      this.detailsName = true;
      this.localTitle = false;
      this.detailsTitle = true;
    },

    // 短信已读未读请求方法
    async smsStatus(val) {
      // 如果是已读状态不必请求接口
      if (val.isread != "1") {
        let parameter = {
          id: val.id,
        };
        await getChangeRead(parameter);
      }
    },

    // 滚动加载联系人列表数据
    rollingLoad() {
      // 获取下面7条数据；
      // eslint-disable-next-line no-unused-vars
      let context = document.getElementsByTagName("context-data");
      let winH = document.documentElement.clientHeight;
      let scroT = document.documentElement.scrollTop;
      // eslint-disable-next-line no-undef
      if (app.clientHeight < winH + scroT + 50) {
        this.flag = false;
        // 在发送下一次请求之前，把flag变成了false;
        this.listContactRequest();
      }
    },
    //短信模板数据请求
    async SmsTemplateRequest() {
      let params = {
        onlyShowMyTemplate: this.searchStatus,
        searchWord: "",
      };
      let res = await getTextMessageGetTextMessageTemplata(params);
      this.templateData = res.data;
    },
    // 主页搜索模板事件
    async searchTemplateChangeEvent(val) {
      this.templateKeywords = val;
      let Template = {
        onlyShowMyTemplate: this.searchStatus,
        searchWord: this.templateKeywords,
      };
      let res = await getTextMessageGetTextMessageTemplata(Template);
      this.templateData = res.data;
    },
    // 关联网关按钮
    // associationGateway() {
    //   this.listContactRequest();
    //   // this.defaultSmsTabPage = false;
    //   this.openSmsTabPage = true;
    // },
    gobottom() {
      this.$nextTick(() => {
        let ele = document.getElementById("window");
        ele.scrollTop = ele.scrollHeight;
      });
    },
    // 点击左侧出右侧内容事件
    async clickShortMessage(item, index) {
      this.isText = "true";
      // this.smsBoxLoading = true;
      this.msgBoxTitle = item.name || "";
      this.dataArrName = item.name;
      // this.smsStatusId = item.id;
      this.relateidObject = item;
      this.showRelateid = this.relateidObject.id;
      this.userId = item.relateid;
      this.mainIndex = index; //鼠标点击加背景颜色判断
      this.isSelect = true; //右侧样式展示
      this.isDefaultSelect = false; //默认样式展示

      // 切换清空输入框内容
      this.textarea = "";
      document.getElementById("demoText").value = "";
      this.msgBoxValue = document.getElementById("demoText").value;

      let params = {
        id: item.relateid || item.relatedid || item.id,
        searchWord: "",
        page: "",
        pageSize: "",
      };

      let res = await getListContacts(params);

      this.recordContent = res.data;
      this.smsStatus(item);
      // 短信箱消息显示到最后一条请求
      this.gobottom();
      this.smsBoxLoading = false;
      this.loading = false;
      // 右侧短信消息标题栏客户名称数据处理
      if (this.recordContent[0].name == null) {
        this.userName = [];
        return;
      } else {
        let idArr = this.recordContent[0].relateid.split(",");
        let nameArr = this.recordContent[0].name.split(",");
        this.userName = [];
        idArr.map((item, index) => {
          this.userName.push({
            id: item,
            name: nameArr[index],
          });
        });
        if (this.userName.length > 1) {
          this.userName.length = 1;
        }
        // 去掉重复客户姓名，
        let obj = {};
        this.userName = this.userName.reduce((cur, next) => {
          obj[next.id] ? "" : (obj[next.id] = true && cur.push(next));
          return cur;
        }, []);
      }
    },
    // 短信箱请求
    async smsListData() {},
    // 点击短信出现短信图片弹框
    clickSmsDialog() {
      this.$refs.mychild.emptyContactArray();
      this.dialogTableVisible = true;
    },
    // 子组件短信弹框群发送按钮
    confirmationMessage(val, text) {
      if (val.length === 0) {
        // 请选择至少一位潜在客户或联系人
        this.$message(this.$i18n.t("vue_label_homepage_notice_selectone")); //请选择至少一位潜在客户或联系人
        return;
      } else if (text === "") {
        // 发送的消息内容不能为空哦
        this.$message(this.$i18n.t("vue_label_SMS_message_sent_cannot_be_empty"));
        return;
      } else if (val.length > 0) {
        let ids = val.map((res) => res.id).join();
        let parameter = {
          ids: ids,
          relateid: this.relateid,
          content: text,
          type: "1",
        };
        this.sendSMSRequest(parameter); //群发发送短信请求
      }
      this.dialogTableVisible = false;
    },
    // 子组件短信弹框分别发送按钮
    sendSeparately(value, text) {
      if (value.length === 0) {
        // 请选择至少一位潜在客户或联系人
        this.$message(this.$i18n.t("vue_label_homepage_notice_selectone")); //请选择至少一位潜在客户或联系人
        return;
      } else if (text === "") {
        this.$message(
          // 发送的消息内容不能为空哦
          this.$i18n.t("vue_label_SMS_message_sent_cannot_be_empty")
        );
        return;
      } else if (value.length > 0) {
        let ids = value.map((res) => res.id).join();
        let parameter = {
          ids: ids,
          relateid: "",
          content: text,
          type: "0",
        };
        this.sendSMSRequest(parameter); //分别发送短信请求
        this.dialogTableVisible = false;
      }
    },
    // 取消隐藏短信弹框
    cancelMssage(state) {
      this.dialogTableVisible = false;
      if (state == "false") {
        this.$refs.wangeditor.clearvalue();
      }
    },
    // 选中的模板
    selectTemplate(items, data) {
      this.isText = items.isText;
      if (items.isText == "false") {
        this.$nextTick(() => {
          this.$refs.wangeditor.clearvalue();
          this.$refs.wangeditor.signContent(
            data ? data.content : "",
            data.content.indexOf("{!") != -1
          );
        });
      }
      if (this.textarea == "") {
        this.textarea += data.content;
      } else if (this.textarea !== "") {
        this.textarea = "";
        this.textarea += data.content;
      }
      // function  filter(text) {
      //   const reg = /<[^<>]+>/g;//1、全局匹配g肯定忘记写,2、<>标签中不能包含标签实现过滤HTML标签
      //   text = text.replace(reg, '');//替换HTML标签
      //   text = text.replace(/&nbsp;/ig, '');//替换HTML空格
      //   return text;
      // }
      // this.textarea = filter(this.textarea)
      // 模板赋值，更改发送按钮状态判断标识
      this.msgBoxValue = this.textarea;
    },
    // 输入框改变事件
    // eslint-disable-next-line no-unused-vars
    handleInputChange(val) {
      this.msgBoxValue = document.getElementById("demoText").value;
    },
    // 选中表情包事件
    // eslint-disable-next-line no-unused-vars
    bindChange(emoji) {
      this.msgBoxValue = document.getElementById("demoText").value;
      //  var elInput = document.getElementById("demoText"); //根据id选择器选中对象
      // var startPos = elInput.selectionStart; // input 第0个字符到选中的字符
      // var endPos = elInput.selectionEnd; // 选中的字符到最后的字符
      // if (startPos === undefined || endPos === undefined) return;
      // var txt = elInput.value;
      // // 将表情添加到选中的光标位置
      // var result =
      //   txt.substring(0, startPos) + emoji.emoji + txt.substring(endPos);
      // elInput.value = result; // 赋值给input的value
      // // 重新定义光标位置
      // elInput.focus();
      // elInput.selectionStart = startPos + emoji.emoji.length;
      // elInput.selectionEnd = startPos + emoji.emoji.length;
      // this.textarea = result; // 赋值给表单中的的字段
      // 
    },

    // 主页的发送
    // eslint-disable-next-line no-unused-vars
    async changeSendOut(textarea) {
      var str;
      if (this.isText == "true") {
        str = document.getElementById("demoText").value;
      } else {
        str = this.editorText;
      }
      // str = document.getElementById("demoText").value;
      this.loading = true;
      if (str.length == 0) {
        this.loading = false;
        this.$notify.info({
          title: this.$i18n.t("chatter.new"),
          //  // 发送的消息内容不能为空哦
          message: this.$i18n.t("vue_label_SMS_message_sent_cannot_be_empty"),
          position: "bottom-right",
        });
        return;
      } else if (str.length > 0) {
        let SmsParameter = {
          ids: this.userId,
          relateid: "",
          content: str,
          type: "1",
        };
        this.sendSMSRequest(SmsParameter); //发送短信请求
      }
    },
    // 主页的分别发送
    // eslint-disable-next-line no-unused-vars
    async sendSingle(textarea) {
      // let str = document.getElementById("demoText").value;
      var str;
      if (this.isText == "true") {
        str = document.getElementById("demoText").value;
      } else {
        str = this.editorText;
      }
      this.loading = true;
      if (str.length == 0) {
        this.loading = false;
        this.$notify.info({
          title: this.$i18n.t("chatter.new"),
          //  // 发送的消息内容不能为空哦
          message: this.$i18n.t("vue_label_SMS_message_sent_cannot_be_empty"),
          position: "bottom-right",
        });
        return;
      } else if (str.length > 0) {
        let SmsParameter = {
          ids: this.userId,
          relateid: "",
          content: str,
          type: "0",
        };
        this.sendSMSRequest(SmsParameter); //发送短信请求
      }
    },
    // eslint-disable-next-line no-unused-vars
    handleKeyBack(event) {
      let e = window.event || arguments[0];
      if (e.key == "Backspace" || e.code == "Backspace" || e.keyCode == 8) {
        this.msgBoxValue = document.getElementById("demoText").value;
      }
    },
    // 回车发送消息
    // eslint-disable-next-line no-unused-vars
    carriageReturn(event) {
      let e = window.event || arguments[0];
      // 
      if (e.key == "Enter" || e.code == "Enter" || e.keyCode == 13) {
        e.returnValue = false;
        this.changeSendOut();
        return false;
      }
    },
    // 群发短信请求
    async sendSMSRequest(val) {
      this.failureField = val.content;
      let Sms = await getTextMessageSendTextMessage(val); //发短信接口
      // 发送后清空内容
      this.textarea = "";
      if (this.isText == "false") {
        this.$refs.wangeditor.clearvalue();
      } else {
        document.getElementById("demoText").value = "";
        this.msgBoxValue = document.getElementById("demoText").value;
      }
      this.isText = "true";
      this.listContactRequest();
      if (Sms.data.sendstatus === "0") {
        //发送成功返回0
        this.listContactRequest();
        setTimeout(() => {
          this.clickShortMessage(this.relateidObject); //发送成功后再次请求一下短信框里面的内容
          this.textarea = "";
        }, 1000);
      } else {
        this.listContactRequest();
        setTimeout(() => {
          this.clickShortMessage(this.relateidObject); //发送成功后再次请求一下短信框里面的内容
          this.textarea = "";
        }, 1000);
      }
    },
    // 发送失败弹框
    // 差号隐藏重新发短信弹框
    difference() {
      this.failInSend = false;
    },
    // 点击取消隐藏重新发短信弹框
    changeCancel() {
      this.failInSend = false;
    },
    // 重新发送事件
    changeResend() {
      this.failInSend = false;
    },
    // 发送失败点击详情看失败列表
    changeDetails() {
      this.failInSend = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.iconSvg {
  display: inline-block;
  width: 37px;
  height: 37px;
}
.sms_box {
  width: 100%;
  height: 100%;
  .not_open_page {
    width: 100%;
    height: 100%;
    background: #fff;
    .reminder_text_box {
      width: 100%;
      height: 110px;
      padding-left: 120px;
      .open_gateway {
        padding: 40px 0 0 369px;
        color: #333333;
        font-size: 16px;
      }
      .reminder_box {
        width: 67%;
        height: 46px;
        color: #333333;
        font-size: 13px;
        padding: 7px 0 0 369px;
      }
    }
    .sms_img_box {
      width: 100%;
      height: 60%;
      position: relative;
      .sms_img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .button_box {
      width: 100%;
      height: 20%;
      position: relative;
      .button_style {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
.sms-page {
  width: 100%;
  height: 100%;

  display: flex;
  // 左侧菜单栏样式
  .left-menu-bar {
    width: 33%;
    // height: 100%;
    background: #ffff;
    border-radius: 3px;
    margin: 10px;
    // ::v-deep .el-input__inner {
    //   border: 1px solid #dddbda;
    //   background: #eaf0f4;
    // }
    // 顶部选择和搜索框
    .selection-box {
      width: 100%;
      height: 60px;
      display: flex;
      .left-selection {
        width: 33%;
        margin: 20px 0 0 16px;
        ::v-deep .el-input__inner {
          height: 28px;
        }
        ::v-deep .el-input__icon {
          line-height: 30px !important;
        }
      }
      .search-box {
        width: 52%;
        margin: 20px 0 0 12px;
        ::v-deep .el-input__inner {
          height: 28px;
        }
      }
      ::v-deep .el-input__icon {
        line-height: 30px;
      }
      .short-message {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border: 1px solid #dddbda;
        margin: 20px 0 0 10px;
        border-radius: 2px;
        cursor: pointer;
        img {
          //position: relative;
          //left: 7px;
          //top: 1px;
        }
      }
    }
    // 内容标题
    .context-title {
      width: 100%;
      height: 35px;
      font-weight: bold;
      padding: 7px 0 0 10px;
      i {
        margin-left: 12px;
        font-weight: bold;
      }
      span {
        margin-left: 12px;
      }
    }
    // 内容数据
    .dataStyle {
      background: #eaf0f4;
    }
    .context-data {
      width: 96%;
      margin-left: 18px;
      //height: 85%;
      height: calc(100% - 95px);
      overflow: auto;
      // cursor: pointer;
      position: relative;
      .no_project {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .data-box {
        width: 100%;
        height: 20%;
        border-width: 0 0 1px 0;
        border-style: solid;
        border-color: #d8d8d8;
        .top-box {
          display: flex;
          width: 100%;
          height: 77px;
          .fillet_box {
            width: 4px;
            height: 4px;
            border: 1px solid #006dcc;
            background: #006dcc;
            border-radius: 2px;
            margin-top: 38px;
          }
          .left_img_box {
            width: 10%;
            padding: 22px 0px 13px 12px;
            img {
              width: 38px;
              height: 38px;
            }
          }
          .right_content_box {
            width: 90%;
            padding: 16px 0 0 12px;
            .lower-box {
              width: 100%;
              height: 28px;
              display: flex;
              justify-content: flex-end;
              .span-name-box {
                min-width: 30%;
                margin-right: auto;
                padding-right: 5px;
                .span-name {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  color: #415c76;
                  font-weight: bold;
                  cursor: pointer;
                }
              }
              .number_and_time {
                max-width: 70%;
                display: flex;
                justify-content: flex-end;
                .display_name {
                  min-width: 10%;
                  padding-right: 5px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  p {
                    color: #415c76;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
                .span-time {
                  max-width: 100%;
                  padding-right: 5px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  p {
                    color: #666666;
                    // white-space: nowrap;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                  }
                }
              }
            }
            .exhibition_content {
              width: 90%;
              display: flex;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
  // 右侧内容
  .right-context {
    width: 70%;
    // height: 100%;
    background: #ffffff;
    margin: 10px 10px 0 0;
    border-radius: 3px;
    position: relative;
    .unchecked_img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .record {
        color: #333333;
        text-align: center;
        font-size: 18px;
        padding-top: 10px;
      }
      .list_record {
        text-align: center;
        padding-top: 10px;
        color: #333333;
        font-size: 14px;
      }
    }
    .send-out-title {
      width: 100%;
      height: 10%;
      border-width: 0 0 1px 0;
      border-style: solid;
      border-color: #d8d8d8;
      background: #dddbda;
      color: #080707;
      text-align: center;
      padding: 17px 15px 0 15px;
      font-weight: bold;
      white-space: normal;
      word-break: break-all;
      overflow: auto;
      .customer-name {
        height: 32px;
        overflow: auto;
        text-align: center;
        white-space: nowrap;
        color: #080707;
        padding-top: 10px;
        background: #ffffff;
      }
      .send-out-name {
        // width: 100%;
        text-align: center;
        color: #666666;
        // padding-top: 9px;
      }
      .contact_name:hover {
        cursor: pointer;
        // font-size: 15px;
        color: #006dcc;
      }
    }
    // .dividing_line {
    //   width: 100%;
    //   height: 1px;
    //   background: #dedcda;
    // }
    .chat-content {
      //margin-top: 10px;
      width: 100%;
      padding: 20px;
      // background: darkorange;
      height: 56%;
      overflow: auto;
      .word {
        display: flex;
        margin-bottom: 60px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        .info {
          width: 47%;
          margin-left: 10px;
          .message_time {
            font-size: 12px;
            color: rgba(51, 51, 51, 0.8);
            margin: 0;
            height: 20px;
            line-height: 20px;
            margin-top: -5px;
            margin-top: 5px;
            color: #7f9ab3;
            font-weight: bold;
          }
          .info-content {
            word-break: break-all;
            // max-width: 45%;
            display: inline-block;
            padding: 10px;
            font-size: 14px;
            background: #fff;
            position: relative;
            margin-top: 8px;
            background: #eaf0f4;
            border-radius: 4px;
            color: #080707;
            border-radius: 8px;
          }
          //小三角形
          .info-content::before {
            position: absolute;
            left: -8px;
            top: 8px;
            content: "";
            border-right: 10px solid #eaf0f4;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
          }
        }
      }

      .word-my {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 60px;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        .info {
          width: 90%;
          // margin-left: 10px;
          text-align: right;
          // position: relative;
          display: flex;
          align-items: flex-end;
          flex-wrap: wrap;
          flex-direction: column;
          .info-content {
            word-break: break-all;
            max-width: 45%;
            padding: 10px;
            font-size: 14px;
            // float: right;
            margin-right: 10px;
            position: relative;
            margin-top: 8px;
            background: #415c76;
            text-align: left;
            border-radius: 4px;
            color: #ffffff;
            border-radius: 8px;
          }
          .Sender_time {
            padding-right: 12px;
            padding-top: 5px;
            font-size: 12px;
            color: rgba(51, 51, 51, 0.8);
            margin: 0;
            height: 20px;
            color: #7f9ab3;
            font-weight: bold;
          }
          //小三角形
          .info-content::after {
            position: absolute;
            right: -8px;
            top: 8px;
            content: "";
            border-left: 10px solid #415c76;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
          }
          .failure_details {
            .fail {
              color: #cc0000;
              padding-right: 20px;
            }
            .details {
              color: #006dcc;
              cursor: pointer;
            }
          }
          .failInSend {
            padding: 4px 12px 0 0;
            color: #cc0000;
            display: flex;
            align-items: center;
            img {
              margin-top: -2px;
              height: 17px;
            }
          }
        }
      }
    }
    .message-area {
      width: 100%;
      height: 60%;
      border: 1px solid #d8d8d8;
    }
    //  模板，表情
    .quick-box {
      width: 100%;
      height: 30px;
      background: #dddbda;
      display: flex;
      align-items: center;
      .template-img {
        margin: 3px 0 0 20px;
        cursor: pointer;
      }
      .expression-img {
        margin: 4px 0 0 8px;
        cursor: pointer;
        ::v-deep .vemoji-main--target {
          span {
            display: inline-block;
            width: 15px;
            height: 15px;
            background: url("../../assets/noteSms/expression.svg") no-repeat
              center center;
            background-size: 15px 15px;
            margin-top: 2.5px;
          }
        }
      }
      .mian_box {
        width: 100%;
        height: 22%;
      }

      ::v-deep .button-box {
        display: flex;
        justify-content: flex-end;
        margin-top: -18px !important;
      }
    }
  }
}
::v-deep .el-textarea__inner {
  border: 0px solid #fff !important;
}
::v-deep .el-button .el-button--primary .el-button--mini .is-disabled {
  background-color: #a0cfff !important;
}
// ::v-deep .el-button--primary {
//   background-color: #415c76 !important;
// }
.dividing-line {
  width: 100%;
  height: 1px;
  // border: 1px solid #dddbda;
}
.template_box {
  width: 243px;
  height: 183px;
  border: 1px solid #d8d8d8;
  margin: 20px 0 0 6px;
  .whole {
    padding: 10px 0 0 10px;
  }
  .template-style {
    width: 210px;
    height: 77%;
    overflow: auto;
    margin-top: 10px;
    margin-left: 29px;
    padding: 0px 22px 0 0px;
    .template_data {
      height: 27%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
    .not_yet {
      padding: 43px 0 0 59px;
    }
  }
}
::v-deep .vemoji-main--content {
  position: absolute;
  margin-top: -261px !important;
}
</style>
<style lang="scss">
.el-popover.template_search_box {
  ::v-deep .el-input {
    width: 94%;
    margin-left: 5px;
  }
}
</style>
